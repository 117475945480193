// src/routes/routes.js
import HomeView from "../View/HomeView";
import ProductsView from "../View/ProductsView/ProductsView";
import AboutView from "../View/AboutView";
import SayswitchPOS from "../View/ProductsView/POSView";
import AgentBanking from "../components/AgentBanking";
import PartnersView from "../View/PartnersView";
import AboutCareerView from "../View/AboutCareerView";
import Pricing from "../components/Pricing/Pricing";
import ContactUs from "../components/Contact/ContactUs";
import SayswitchAndProcessing from "../View/ProductsView/ProcessView";
import PrivacyAndCookies from "../components/Privacy/PrivacyAndCookies";
import CheckoutView from "../View/ProductsView/CheckoutView";
import CollectionAndDisb from "../View/ProductsView/CollectionView";
import MobileAppView from "../View/MobileAppView";
import DocumentsView from "../View/DeveloperView/DocumentsView";
import ApiView from "../View/DeveloperView/ApiView";
// import SignupPage from "../View/SignUp";
// import SignInPage from "../View/SignIn";

// Helper to redirect to external URL
const redirectToExternalUrl = (url) => {
	return () => {
		window.location.href = url;
		return null;
	};
};

const routes = (props) => [
	{
		path: "/",
		component: HomeView,
		exact: true,
	},
	{
		path: "/getstarted",
		component: redirectToExternalUrl("https://merchant.sayswitchgroup.com/create-account"),
		exact: true,
	},
	{
		path: "/login",
		component: redirectToExternalUrl("https://merchant.sayswitchgroup.com/login"),
		exact: true,
	},
	{
		path: "/products",
		component: ProductsView,
		exact: true,
	},
	{
		path: "/about",
		component: AboutView,
		exact: true,
		// props, // passing props if needed
	},
	{
		path: "/pos",
		component: SayswitchPOS,
		exact: true,
	},
	{
		path: "/agentbanking",
		component: AgentBanking,
		exact: true,
	},
	{
		path: "/partners",
		component: PartnersView,
		exact: true,
	},
	{
		path: "/aboutcareers",
		component: AboutCareerView,
		exact: true,
	},
	{
		path: "/pricing",
		component: Pricing,
		exact: true,
	},
	{
		path: "/contactus",
		component: ContactUs,
		exact: true,
	},
	{
		path: "/sayprocessing",
		component: SayswitchAndProcessing,
		exact: true,
	},
	{
		path: "/privacycookies",
		component: PrivacyAndCookies,
		exact: true,
	},
	{
		path: "/checkout",
		component: CheckoutView,
		exact: true,
	},
	{
		path: "/collection",
		component: CollectionAndDisb,
		exact: true,
	},
	{
		path: "/mobileapp",
		component: MobileAppView,
		exact: true,
	},
	{
		path: "/documents",
		component: DocumentsView,
		exact: true,
	},
	{
		path: "/api",
		component: ApiView,
		exact: true,
	},
];

export default routes;
